<template>
  <div class="appointmentManagement-view">
    <el-tabs class="tabs" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="预约列表" name="first"
        ><appointmentList v-if="activeName == 'first'" :jurisdiction="jurisdiction"
      /></el-tab-pane>
      <el-tab-pane label="商品列表" name="second"
        ><appointmentGoodsList
          v-if="activeName == 'second'"
          :jurisdiction="jurisdiction"
        ></appointmentGoodsList
      ></el-tab-pane>
      <el-tab-pane label="产品分类" name="third"
        ><classifyList v-if="activeName == 'third'" :jurisdiction="jurisdiction"></classifyList
      ></el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import appointmentList from "./appointment/appointmentList";
import appointmentGoodsList from "./appointment/appointmentGoodsList";
import classifyList from "./appointment/classifyList";
import { mapState } from 'vuex';

export default {
  name: "appointmentManagement",
  components: {
    appointmentList,
    appointmentGoodsList,
    classifyList,
  },
  data() {
    return {
      activeName: "first",
      jurisdiction:false,
    };
  },
  computed: {
    ...mapState(['routerArr']),
  },
  watch:{
    routerArr(val){
      this.jurisdiction = val.includes("041")
    },
  },
  methods: {
    handleClick(tab) {
      const { name } = tab;
      this.activeName = name;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>